/**
 * Service for accessing the back-end API
 */
export default class ApiService {
  static _getBaseUrl() {
    return `${window.location.origin}/api`;
  }
  /**
   * Makes a GET request to the supplied URL with query parameters, and returns the Promise
   * @param urlPath: the relative URL path to request
   * @param queryParams: Optional dictionary of query parameters
   * @returns {Promise<*>}
   * @private
   */
  static _getRequest(urlPath) {
    const url = `${ApiService._getBaseUrl()}/${urlPath}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        const statusCode = response.status;
        return response.json().then(error => {
          console.error(`${statusCode} error on GET ${urlPath}: ${error}`);
          throw {statusCode, error};
        });
      }
    })
  }

  /**
   * Makes a POST request to the supplied URL with the body, and returns the Promise
   * @param urlPath the relative URL path to request
   * @param data Dictionary of post request data to send
   * @returns {Promise<*>}
   * @private
   */
  static _postRequest(urlPath, data) {
    const url = `${ApiService._getBaseUrl()}/${urlPath}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': ApiService._getCSRFToken()
      },
      body: JSON.stringify(data),
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        const statusCode = response.status;
        return response.json().then(error => {
          console.error(`${statusCode} error on POST ${urlPath}: ${JSON.stringify(error)}`);
          throw {statusCode, error};
        });
      }
    })
  }

  /**
   * Gets the CSRF token from the page
   */
  static _getCSRFToken(){
    return document.querySelector("[name='csrfmiddlewaretoken']").value;
  }

  /**
   * Gets the current user's data
   * URL: /api/user/
   */
  static getUser() {
    return ApiService._getRequest('user')
  }

  /**
   * Sets user details if needed
   */
  static setUserDetails(data) {
    return ApiService._postRequest('user/set_details/', data);
  }

  /**
   * Redeems an offer
   * @param offerId The id of the offer to redeem
   */
  static redeemOffer(offerId) {
    return ApiService._postRequest(`offers/${offerId}/redeem/`);
  }

  /**
   * Creates a survey response
   * @param offerRedemptionId The id of the offerRedemption for this survey
   * @param answers  A dictionary of all the question answers
   */
  static createSurveyResponse(offerRedemptionId, answers) {
    const postData = Object.assign({}, answers, {offer_redemption_id: offerRedemptionId});
    return ApiService._postRequest(`survey_response/`, postData);
  }

  /**
   * Snoozes prompt times for all pending surveys for the user
   */
  static snoozeSurveyPrompts() {
    return ApiService._postRequest(`survey_snooze/`, {});
  }


  /**
   * Gets a single DynamicPageSection object
   * @param sectionId the db Id of the page section
   */
  static getPageSection(sectionId) {
    return ApiService._getRequest(`page_section/${sectionId}`)
  }
}
